import React, { useState } from 'react'
import { TableComponent } from '../../../../components/table-component/table'
import { IconButton, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { Methods } from '../../../../core/utility';
import { Link } from 'react-router-dom';
import MaterialSymbol from '../../../../components/icons/material-symbol';


type Props = {
  postFilterData?: any;
  columnDisplayName: any;
  orderByColumnName?: any;
  inputDataSource: any;
  sortingColumnType: any;
  renderData: any;
  setRenderData: any;
  selectCampaign?: any;
  postAnalyticsEr?: any;
  selectDeleteRecord?: any;
  handleOpenDeleteURL?:any;
  hasShareAnalytics?:any;
}

const methods: Methods = new Methods()

const DetailedInsightsPost = (props: Props) => {
  return (
    <>
      {
        (() => {
          if (props?.inputDataSource.length > 0) {
            return (
              <>
                <TableComponent
                  columnDisplayName={props?.columnDisplayName}
                  orderByColumnName=""
                  inputDataSource={props?.inputDataSource}
                  sortingColumnType={props?.sortingColumnType}
                  renderData={props?.renderData}
                  setRenderData={props?.renderData}
                >
                  {props?.inputDataSource?.map((data: any, index: number) => {

                    return (

                      <TableRow key={index} hover role="checkbox">
                        <TableCell>
                          <div className="grid-div-post d-flex">
                            {/* <Link to={`brand/profile-insight?sm_id=${}&influencerId=${}`}> */}
                            <Link target='_blank' to={props?.selectCampaign?.campaign_platform === 'Instagram' ? `https://www.instagram.com/p/${data?.shortcode}` : `https://www.youtube.com/watch?v=${data?.video_id}`}>
                              <img src={props?.selectCampaign?.campaign_platform === 'Instagram' ? data?.gcp_display_url : data?.thumbnails} className="grid-image me-2" />
                            </Link>

                            {/* </Link> */}
                            <div className='d-flex flex-column align-items-start justify-content-between'>
                              <div className='d-flex flex-column align-items-start'>
                                <span>{data.full_name}</span>
                                <Link target='_blank' className='btn btn-text-link mb-1' to={props?.selectCampaign?.campaign_platform === 'Instagram' ? `https://www.instagram.com/${data?.username}` : `https://www.youtube.com/channel/${data?.channel_id}`}>{props?.selectCampaign?.campaign_platform === 'Instagram' ? data?.username : data?.channel_title}</Link>
                              </div>

                              {props?.selectCampaign?.campaign_platform === 'Instagram' ? (
                                <span style={{ color: '#787878' }}>Duration: {data?.__typename === "GraphVideo" ? `${methods.formatDuration(data?.video_duration)}` : ""}</span>
                              ) : (
                                <Link target='_blank' className='mb-1 text-black text-decoration-none' to={`https://www.youtube.com/watch?v=${data?.video_id}`}>
                                  <span>{data?.title} </span>
                                </Link>
                              )
                              }
                            </div>
                          </div>
                        </TableCell>
                        {!props.hasShareAnalytics &&
                        <TableCell>
                          <IconButton onClick={() => {
                            props.selectDeleteRecord(data.url_id)
                            props.handleOpenDeleteURL()
                            }}><MaterialSymbol iconName='delete' fontweight='200' color='red' /></IconButton>
                        </TableCell>
                        }
                        {props?.selectCampaign?.campaign_platform === 'Youtube' &&
                          <>
                            {
                              (() => {
                                const matchingItem = props?.postAnalyticsEr && props?.postAnalyticsEr.find((item: any) => item?.url_id === data.url_id);

                                return (
                                  <TableCell>
                                    {matchingItem ? matchingItem?.result.toFixed(2) : "NA"}
                                  </TableCell>
                                );
                              })()
                            }
                          </>
                        }
                        {props?.selectCampaign?.campaign_platform === 'Instagram' && (
                          <TableCell>{data?.er && data?.er ? `${data?.er}%` : "NA"}</TableCell>
                        )}
                        <TableCell>
                          {props?.selectCampaign?.campaign_platform === 'Instagram'
                            ? (data?.__typename === 'GraphVideo'
                              ? methods.formatInfluencerProfileNumber(data?.video_plays)
                              : "--")
                            : methods.formatInfluencerProfileNumber(data?.view_count)}

                        </TableCell>
                        <TableCell>
                          {props?.selectCampaign?.campaign_platform === 'Instagram'
                            ? (data?.likes !== 0
                              ? methods.formatInfluencerProfileNumber(data?.likes)
                              : "likes are disabled by the user")
                            : methods.formatInfluencerProfileNumber(data?.like_count)}

                        </TableCell>
                        <TableCell>{props?.selectCampaign?.campaign_platform === 'Instagram' ? methods.formatInfluencerProfileNumber(data?.comments) : methods.formatInfluencerProfileNumber(data?.comment_count)}</TableCell>
                        <TableCell style={{minWidth:'130px'}}>{dayjs(data?.created_at).format("MMM D, YYYY")}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableComponent>
              </>
            )
          }

        })()

      }
    </>
  )
}

export default DetailedInsightsPost